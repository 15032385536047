import React from 'react'

import { vaktija } from '../services/vaktija'

import Day from './calendarDay'
import Tip from './calendarTip'

import PrintPage from './printPage'

export default class Calendar extends React.PureComponent {

	mCallbackId = undefined

	/**
	 * React lifecycle method called when component was added to DOM
	 * @return {[type]} [description]
	 */
	componentDidMount = () => {

		// subscribe to data updated callbacks
		this.mCallbackId = vaktija.registerOnTimeCallback(() => {
			this.forceUpdate();
			//console.log('Callendar - Component updated')
		});

		// reload data when app is focues
		window.onfocus = (e) => {
			this.forceUpdate();
		};
  	}

  	/**
  	 * React callback that is triggered just before the component is removed
  	 * from DOM
  	 * @return {[type]} [description]
  	 */
  	componentWillUnmount = () => {
  		vaktija.unregisterOnTimeCallback(this.mCallbackId);
  	}

	/**
     * React render function
     */
    render = () => {

    	//console.log('Callendar - render called')

    	let months = vaktija.getPrayersCalendar();

		return (
			<React.Fragment>
				<div>

					{months.map((m, id) => {

						let days = m.days.map((d, id) => {

							if (!d.date) return [];

							let events = d.events.map((e, id) => {
								return (
									<Tip key={id} title={e.name} content={e.description} />
								);
							});

							return (
								<div key={id}>
									<Day date={d.date} times={d.times} />
									{events}
								</div>
							)
						});

						return (
							<React.Fragment key={id}>
								<div className="feed-section clearfix">
									<div className="feed-section-title">{m.name}</div>
									<div className="print-icon" onClick={this.printPage.bind(this, m.year, m.month) }>
										Natisni
									</div>
								</div>
								{days}
							</React.Fragment>
						);
	    			})}

				</div>

				<div style={{display: 'none'}}>
					<PrintPage ref="printPage" />
				</div>

			</React.Fragment>
		);
	}

	/**
	 * This function is called when print is requested.
	 * @param  {[type]} month [description]
	 * @return {[type]}       [description]
	 */
	printPage = (year, month) => {
		this.refs.printPage.print(year, month)
	}

}