import React from 'react'

export default class Day extends React.PureComponent {

	/**
     * React render function
     */
    render = () => {
    	let date = this.props.date;
    	if (!date) return []; 

    	let times = this.props.times;

		return (
			<div className="feed-box calendar-day">
				<div className="calendar-day-header clearfix">
					<span className="day">{date.day}</span>
					<span className="name">{date.weekdayName}</span>
					<span className="today">{date.weekday === 5 ? '(džuma)' : ''}</span>
					<span className="hijra-date">{date.hDay}. {date.hMonthName} {date.hYear}</span>
				</div>
				<div className="calendar-day-prayers clearfix">
					{times.map((t, id) => {
						return (
							<div key={id} className="item">
								<div className="name">{t.nameShort}</div>
								<div className="time">{t.time}</div>
							</div>
						);
					})}

				</div>
			</div>
		);
	}
}