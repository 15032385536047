import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import { vaktija } from '../services/vaktija'

export default class CitySelect extends React.PureComponent {

	/**
     * React render function
     */
    render = () => {

    	let cities = vaktija.getCities();
    	let citiesSelect = cities.map(c => { 
    		return (
    			<div className="app-city-item" key={c.cityid} onClick={() => this.props.onCitySelected(c)}>{c.name}</div>
    		);
    	})

		return (
			<div className="app">

				<header className={'app-header' + (this.props.backButton && ' app-back')}>
					<Container>
			          <Row>
			            <Col 
			              sm={{ size: 12, offset: 0 }}
			              md={{ size: 10, offset: 1 }}
			              lg={{ size: 8, offset: 2 }}
			              xl={{ size: 6, offset: 3 }}>
					          
					          <div className="app-header-logo" />
					          { this.props.backButton && <div className="app-back-button" onClick={() => this.props.onBackClicked()}></div> }

			            </Col>
			          </Row>
			        </Container> 
		        </header>

				<Container>
		          <Row>
		            <Col 
		              sm={{ size: 12, offset: 0 }}
		              md={{ size: 10, offset: 1 }}
		              lg={{ size: 8, offset: 2 }}
		              xl={{ size: 6, offset: 3 }}>
		              <div className="app-content">
		                <div className="app-city-select">
							<p>Za katero mesto želite vaktijo? <br />
							<small>Izbiro lahko kadar koli spremenite.</small></p>
							<div className="feed-box cities-select">
								{citiesSelect}
							</div>
							<div className="isvrs-logo" />
						</div>
		              </div>
		            </Col>
		          </Row>
		        </Container> 

			</div>
		);
	}
}