import React from 'react'

import { vaktija } from '../services/vaktija'

export default class CurrentPrayers extends React.PureComponent {

	mCallbackId = undefined;

	/**
	 * React lifecycle method called when component was added to DOM
	 * @return {[type]} [description]
	 */
	componentDidMount = () => {

		// subscribe to data updated callbacks
		this.mCallbackId = vaktija.registerOnTimeCallback(() => {
			this.forceUpdate();
			//console.log('Current prayers - Component updated')
		});

		// reload data when app is focues
		window.onfocus = (e) => {
			this.forceUpdate();
		};
  	}

  	/**
  	 * React callback that is triggered just before the component is removed
  	 * from DOM
  	 * @return {[type]} [description]
  	 */
  	componentWillUnmount = () => {
  		vaktija.unregisterOnTimeCallback(this.mCallbackId);
  	}

	/**
     * React render function
     */
    render = () => {

    	//console.log('Current prayers - render called')

    	let prayers = vaktija.getCurrentPrayers()

    	let time = vaktija.getServerTime().getTime()
    	let nextTime = prayers.next[0].timestamp;

    	let m = Math.ceil(((nextTime - time) / 60000) % 60),
    		h = Math.floor(((nextTime - time) / 60000) / 60);

    	let timer = '';
    	if (m > 0) {
    		timer += m + ' min';
    	}
    	if (h > 0) {
    		if (h === 1) timer = h + ' uro in ' + timer;
    		else if (h === 2) timer = h + ' uri in ' + timer;
    		else if (h === 3 || h === 4) timer = h + ' ure in ' + timer;
    		else timer = h + ' ur in ' + timer;
    	}
		return (
			<div>

				{this.renderRamazanBox(prayers.current.name, prayers.next[0].time, timer, vaktija.getConfig())}
				
				<div className="feed-section clearfix first">
					<div className="feed-section-title">trenutno</div>
				</div>

				{this.renderPrayer(prayers.current.name, prayers.current.time, prayers.current.timestamp, true)}

				<div className="feed-section clearfix">
					<div className="feed-section-title">sledi</div>
					<div className="feed-section-timer">čez {timer}</div>
				</div>

				{prayers.next.map(p => {
					return this.renderPrayer(p.name, p.time, p.timestamp)
				})}

			</div>
		);
	}


	/**
     * Renders a single prayer box
     */
	renderPrayer = (name, time, date, active) => {
		var classname = 'feed-box prayer clearfix';
		if (active) {
			classname += ' active';
		}

		return (
			<div key={name} className={classname}>
				<div className="prayer-name">{this.wrapName(name, date)}</div>
				<div className="prayer-time">{time}</div>
			</div>	
		);
	}

	/**
	 * Correct prayer names if ramazan
	 */
	wrapName = (name, time) => {

		let conf = vaktija.getConfig();
		let date = new Date(time);

		if (conf.date.hMonth === 8 && conf.tomorrow.hMonth === 9) {
			if (name === 'Sabah') {
				return (
					<div>Sabah  <span>(Sehur)</span></div>
				);
			};
		}
		else if (conf.date.hMonth === 9 && conf.tomorrow.hMonth === 9) {
			if (name === 'Sabah') {
				return (
					<div>Sabah  <span>(Sehur)</span></div>
				);
			}
			else if (name === 'Akšam') {
				return (
					<div>Akšam  <span>(Iftar)</span></div>
				);
			}
			else if (name === 'Jacija') {
				return (
					<div>Jacija  <span>(Teravija)</span></div>
				);
			};
		}
		else if (conf.date.hMonth === 9 && conf.tomorrow.hMonth === 10) {
			if (name === 'Sabah' && conf.date.timestamp < time) {
				return (
					<div>Sabah  <span>(Sehur)</span></div>
				);
			}
			else if (name === 'Akšam') {
				return (
					<div>Akšam  <span>(Iftar)</span></div>
				);
			}
		}

		if (date.getDay() === 5 && name === 'Poldan') {
			return (
				<div>Poldan  <span>(Džuma)</span></div>
			);
		}


		return name;
	}


	/**
	 * This function will render an aditional box on top when ramazan days
	 */
	renderRamazanBox = (name, time, timer, config) => {

		//console.log(config)

		// check if we should render the ramazan box
		// we only render in ramazan and one prayer before sehur and iftar and one prayer after iftar
		
		if (name === 'Jacija' && (
				(config.date.hMonth === 8 && config.tomorrow.hMonth === 9) || 
				(config.date.hMonth === 9 && config.tomorrow.hMonth < 10)
			)) {
			return (
				<div className="feed-box ramazan-box clearfix">
					<div className="name-and-time">Sehur čez {timer}</div>
					<div className="dua-title">sehurska dova</div>
					<div className="dua-org">
						Bismillahi-r-rahmani-r-rahim! <br/>
						Nevejtu en esume lillahi te’ala gaden min šehri-ramazan.
					</div>
					<div className="dua-trans">
						V imenu Allaha, Milostnega, Usmiljenega. <br/>
						V imenu Allaha sem se odločil postit se jutrišnji dan.
					</div>
				</div>	
			);
		}
		else if ((name === 'Ikindija' || name === 'Akšam') &&
				config.date.hMonth === 9) {

			let title = name === 'Ikindija' ? 'Iftar čez ' + timer : 'Iftar je, dober tek!';

			return (
				<div className="feed-box ramazan-box clearfix">
					<div className="name-and-time">{title}</div>
					<div className="dua-title">iftarska dova</div>
					<div className="dua-org">
						Bismillahi-r-rahmani-r-rahim! <br/>
						Allahumme inni leke sumtu ve bike amentu ve'alejke tevekkeltu ve bi rizkike eftartu.
					</div>
					<div className="dua-trans">
						V imenu Allaha, Milostnega, Usmiljenega. <br/>
						Milostni Bog, postil sem se v Tvojem imenu, verjamem v Tebe, zanašam se na Tebe in prekinjam post s Tvojo hrano.
					</div>
				</div>	
			);
		}
		else {
			return '';
		}
	}
}