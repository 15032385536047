import React from 'react'

export default class Tip extends React.PureComponent {

	/**
     * React render function
     */
    render = () => {

    	let text = []
    	if (this.props.content) {
    		text = <div className="content">{this.props.content}</div>;
    	}
		return (
			<div className="feed-box calendar-tip">
				<div className="title">{this.props.title}</div>
				{text}
			</div>
		);
	}
}