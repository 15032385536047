export let MONTHS = [
	'januar', 
	'februar', 
	'marec', 
	'april', 
	'maj', 
	'junij', 
	'julij', 
	'avgust', 
	'september', 
	'oktober', 
	'november', 
	'december'
];

export let HIJRI_MONTHS = [
	'muharrem', 
	'safar', 
	'rebi-ul-evvel', 
	'rebi-ul-ahir', 
	'džumade-l-ula', 
	'džumade-l-uhra', 
	'redžeb', 
	'ša\'ban',
	'ramazan', 
	'ševval', 
	'zu-l-ka\'de',
	'zu-l-hidždže'
];

export let WEEKDAYS = [
	'ned',
	'pon', 
	'tor', 
	'sre', 
	'čet', 
	'pet', 
	'sob'
];

export let PRAYERS = [
	'fajr',
	'sunrise',
	'dhuhr',
	'asr',
	'maghrib',
	'isha'
]; 

export let PRAYER_NAMES = {
	fajr: 		'Sabah',
	sunrise: 	'Vzhod sonca',
	dhuhr: 		'Poldan',
	asr: 		'Ikindija',
	maghrib: 	'Akšam',
	isha: 		'Jacija',
}; 

export let PRAYER_NAMES_SHORT = {
	fajr: 		'Sab',
	sunrise: 	'Vz.s',
	dhuhr: 		'Pol',
	asr: 		'Iki',
	maghrib: 	'Akš',
	isha: 		'Jac',
}; 
