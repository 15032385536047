import React from 'react'

import '.././style/loading.scss'

export default class Main extends React.PureComponent {

	/**
     * React render function
     */
    render = () => {
		return (
			<div className="app-loading">
				<div className="isvrs-logo" />

				<div className="app-loader">
					<div className="sk-circle">
						<div className="sk-circle1 sk-child"></div>
						<div className="sk-circle2 sk-child"></div>
						<div className="sk-circle3 sk-child"></div>
						<div className="sk-circle4 sk-child"></div>
						<div className="sk-circle5 sk-child"></div>
						<div className="sk-circle6 sk-child"></div>
						<div className="sk-circle7 sk-child"></div>
						<div className="sk-circle8 sk-child"></div>
						<div className="sk-circle9 sk-child"></div>
						<div className="sk-circle10 sk-child"></div>
						<div className="sk-circle11 sk-child"></div>
						<div className="sk-circle12 sk-child"></div>
					</div>
					<p>Prosim počakajte...</p>
				</div>

				<div className="app-header-logo" />
			</div>
		);
	}
}