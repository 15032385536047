import axios from 'axios';

import { MONTHS, HIJRI_MONTHS, WEEKDAYS, PRAYERS, PRAYER_NAMES, PRAYER_NAMES_SHORT } from './consts'

// const config = {
// 	protocol: 	'http',
// 	url: 		'localhost',
// 	port: 		5000
// }

/**
 * This is the class that will handle everything
 */
class Vaktija {

	// cities
	mCities = []
	mCitiesHash = {}

	// time
	mServerTimeDiff = 0

	// calendar
	mCalendar = []
	mCalendarHash = {}
	mCalendarDaysHash = {}

	// prayers
	mPrayers = []
	mPrayersHash = {}

	// eventds
	mEvents = []
	mEventsHash = {}

	// callbacks
	mInitCallbackRef = 0;
	mInitCallbackRegister = {}

	// callbacks
	mTimeCallbackRef = 0;
	mTimeCallbackRegister = {}

	// last server time
	mLastServerTime = undefined

	// query parameters
	mParams = {}

	/**
	 * Call this function to initialise this service
	 * @return {[type]} [description]
	 */
	initialise = () => {

		// query parameters
		let search = window.location.search.substring(1);
		if (search) {
			this.mParams = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
		}

		this.fetchFeed();
		setInterval(() => { 

			// do we have a server time
			if (!this.mLastServerTime) {
				this.mLastServerTime = this.getServerTime();
				return;
			}

			// did we enter into new minute
			if (this.mLastServerTime.getMinutes() === this.getServerTime().getMinutes()) {
				return;
			}

			// notify listeners
			Object.keys(this.mTimeCallbackRegister)
				  .forEach(c => this.mTimeCallbackRegister[c]())
			this.mLastServerTime = this.getServerTime();


		}, 1000);
	}

	/**
	 * Register initialised callback
	 */
	registerOnInitCallback(callback) {

		var idx = this.mInitCallbackRef++;
		this.mInitCallbackRegister[idx] = callback;
		return idx;
	}

	/**
	 * Register initialised callback
	 */
	unregisterOnInitCallback(callbackId) {

		if (this.mInitCallbackRegister[callbackId]) {
			delete this.mInitCallbackRegister[callbackId];
		}
	}

	/**
	 * Register time interval change callback
	 */
	registerOnTimeCallback(callback) {

		var idx = this.mTimeCallbackRef++;
		this.mTimeCallbackRegister[idx] = callback;
		return idx;
	}

	/**
	 * Unregister time interval change callback
	 */
	unregisterOnTimeCallback(callbackId) {

		if (this.mTimeCallbackRegister[callbackId]) {
			delete this.mTimeCallbackRegister[callbackId];
		}
	}

	/**
	 * This function will return base url for rest requests
	 * @return {[type]} [description]
	 */
	url = (path) => {

		return path;

		// var base = (config.protocol ? (config.protocol + '://') : '') +
		// 	   	   config.url +
		// 	   	   (config.port ? (':' + config.port) : '') ;

		// return base + path;
	}

	/**
	 * Call this function to fetch the feed
	 * @return {[type]} [description]
	 */
	fetchFeed = () => {

		axios
			.get(this.url('/api/v2/feed'))
			.then(response => {
				if (response.data) {
					this.handleFeedResponse(response.data);

					// notify listeners
					Object.keys(this.mInitCallbackRegister)
						  .forEach(c => this.mInitCallbackRegister[c]())
				}
			});
	}

	/**
	 * This function will handle the response
	 * @return {[type]} [description]
	 */
	handleFeedResponse = (data) => {

		//------ calculate server time diff ------------------
		this.mServerTimeDiff = new Date().getTime() - data.serverTime;
		if (this.mParams.forceTime) {
			var overrideTime = new Date(this.mParams.forceTime);
			this.mServerTimeDiff = new Date().getTime() - overrideTime.getTime();	
		}

		//------ cities --------------------------------------
		this.mCities = []
		this.mCitiesHash = {}
		data.cities.forEach((c) => {
			let city = {
				...c
			}
			this.mCities.push(city);
			this.mCitiesHash[city.cityid] = city;
		});

		//------ calendar -----------------------------------
		this.mCalendar = []
		this.mCalendarHash = {}
		this.mCalendarDaysHash = {}
		data.calendar.forEach((d) => {

			// get belonging month
			const monthKey = d.year + '_' + d.month;
			let month = this.mCalendarHash[monthKey];
			if (!month) {
				month = {
					year: 	d.year,
					month: 	d.month + 1,
					name: 	MONTHS[d.month] + ' ' + d.year,
					days: 	[]
				};
				this.mCalendarHash[monthKey] = month;
				this.mCalendar.push(month);
			}

			// index day
			let day = {
				day: 		 d.day,
				month: 		 d.month + 1,
				monthName: 	 MONTHS[d.month],
				year: 		 d.year,
				hDay: 		 d.hDay,
				hMonth: 	 d.hMonth + 1,
				hMonthName:  HIJRI_MONTHS[d.hMonth],
				hYear:       d.hYear,
				weekday:     d.weekday,
				weekdayName: WEEKDAYS[d.weekday],
				timestamp:   d.time
			};
			month.days.push(day);

			var dayKey = d.year + '_' + d.month + '_' + d.day;
			this.mCalendarDaysHash[dayKey] = day;
		});


		//------ events -------------------------------------
		this.mEvents = []
		this.mEventsHash = {}
		if (data.events && data.events.gregorian) {
			data.events.gregorian.forEach(e => {
				const eventKey = 'g_' + (e.month - 1) + '_' + e.day;
				let event = {
					type: 		 'gregorian',
					name: 		 e.name,
					description: e.description,
					time: 		 e.time
				};
				this.mEvents.push(event);
				if (!this.mEventsHash[eventKey]) {
					this.mEventsHash[eventKey] = [];
				}
				this.mEventsHash[eventKey].push(event);
			});
		}
		if (data.events && data.events.hijri) {
			data.events.hijri.forEach(e => {
				const eventKey = 'h_' + (e.month - 1) + '_' + e.day;
				let event = {
					type: 		 'hijri',
					name: 		 e.name,
					description: e.description,
					time: 		 e.time
				};
				this.mEvents.push(event);
				if (!this.mEventsHash[eventKey]) {
					this.mEventsHash[eventKey] = [];
				}
				this.mEventsHash[eventKey].push(event);
			});
		}

		// ------ prayer times ------------------------------
		let now = new Date(this.getServerTime());
		this.mPrayers = []
		this.mPrayersHash = {}
		data.times.forEach(m => {

			let month = {
				month: 	m.month,
				year: 	m.year,
				name: 	m.name,
				days: 	[]
			};

			let fullMonth = {
				month: 	m.month,
				year: 	m.year,
				name: 	m.name,
				days: 	[]
			};

			// filter for excluding past days
			if (m.year > now.getFullYear() || 
				(m.year === now.getFullYear() && (m.month - 1) >= now.getMonth())) {
				this.mPrayers.push(month);
				this.mPrayersHash[m.year + '_' + m.month] = fullMonth;
			}

			m.days.forEach(d => {

				try {

					// asemble prayer days
					const dayKey = m.year + '_' + (m.month - 1) + '_' + d.day;
					let day = { 
						date: 		this.mCalendarDaysHash[dayKey],
						events: 	[],
						times: 		[]
					};

					// filter if calendar date does not exist
					if (!day.date) { 
						return
					}

					// attach prayer times
					day.times = PRAYERS.map(salat => {
						var parts = d[salat].split(':');
						return {
							id: 		salat,
							name:   	PRAYER_NAMES[salat],
							nameShort:  PRAYER_NAMES_SHORT[salat],
							time:   	d[salat],
							timestamp:	new Date(m.year, (m.month - 1), d.day, parts[0], parts[1]).getTime()
						}
					});

					// attach events gregorian
					let eventKey = 'g_' + (day.date.month - 1) + '_' + day.date.day;
					let events = this.mEventsHash[eventKey];
					if (events) {
						events.forEach(e => day.events.push(e));
					}

					// attach hijri gregorian
					eventKey = 'h_' + (day.date.hMonth - 1) + '_' + day.date.hDay;
					events = this.mEventsHash[eventKey];
					if (events) {
						events.forEach(e => day.events.push(e))
					}

					fullMonth.days.push(day);
					// filter for excluding past days
					if (m.year === now.getFullYear() && 
						(m.month - 1) === now.getMonth() &&
						d.day < now.getDate() - 1) {
						return;
					}
					month.days.push(day);
				}
				catch (ex) {}
			});
		});
	}

	/**
	 * Returns the current settings
	 * @return {[type]} [description]
	 */
	getConfig = () => {
		let serverTime = this.getServerTime();
		const dayKey = serverTime.getFullYear() + '_' + 
					   serverTime.getMonth() + '_' + 
					   serverTime.getDate();

		let tomorrow = new Date(serverTime);
		tomorrow.setDate(tomorrow.getDate() + 1);

		const tomorrowKey = tomorrow.getFullYear() + '_' + 
					   		tomorrow.getMonth() + '_' + 
					   		tomorrow.getDate();

		return {
			city: this.getSelectedCity(),
			time: serverTime,
			date: this.mCalendarDaysHash[dayKey],
			tomorrow: this.mCalendarDaysHash[tomorrowKey],
		}
	}

	/**
	 * This functin will return the current server time
	 * @return {[type]} [description]
	 */
	getServerTime = () => {
		return new Date(new Date().getTime() - this.mServerTimeDiff)
	}

	/**
	 * This function will return a list of available cities
	 * @return {[type]} [description]
	 */
	getCities = () => {

		return this.mCities;
	}

	/**
	 * This functin will return the prayer time corrections
	 * for the selected city
	 * @return {[type]} [description]
	 */
	getSelectedCity = () => {

		let cityid = localStorage['vaktija_selectedCity'];
		if (cityid) {
			return this.mCitiesHash[cityid];
		}
		return undefined;
	}

	/**
	 * Call this functin to select a new city
	 * @param  {[type]} cityid [description]
	 * @return {[type]}        [description]
	 */
	setSelectedCity = (cityid) => {

		if (this.mCitiesHash[cityid]) {
			localStorage['vaktija_selectedCity'] = cityid;
		}
	}

	/**
	 * This functin will return a calendar data.
	 * 
	 * @return {[type]} [description]
	 */
	getCalendar = () => {

		return this.mCalendar;
	}

	/**
	 * This functin will return the current prayers calendar
	 * @return {[type]} [description]
	 */
	getPrayersCalendar = () => {

		let serverTime = this.getServerTime();
		let today = new Date(serverTime.getFullYear(), serverTime.getMonth(), serverTime.getDate());
		let todayTS = today.getTime();

		var result = [];

		this.mPrayers.forEach(m => {
			let month = {
				year:  m.year,
				month: m.month,
				name:  m.name,
				//days:  m.days.filter(d => d.date.timestamp < todayTS)
				days: []
			}

			m.days.forEach(d => {
				if (d.date.timestamp < todayTS) {
					return;
				}
				month.days.push({
					date: 		d.date,
					events: 	d.events,
					times: 		this.applyTimesCorrections(d.times)
				});
			})

			if (month.days.length > 0) {
				result.push(month);
			}
		});

		return result;
	}

	/**
	 * This functin will return the current prayer .
	 */
	getCurrentPrayers = () => {

		var result = {
			current: undefined,
			next: []
		};

		var serverTime = this.getServerTime().getTime();

		for (var i = 0; i < this.mPrayers.length; i++) {
			var month = this.mPrayers[i];
			for (var j = 0; j < month.days.length; j++) {
				var day = month.days[j];
				for (var k = 0; k < day.times.length; k++) {
					var prayer = day.times[k];
					if (!result.current || prayer.timestamp < serverTime ) {
						result.current = this.applyTimeCorrection(prayer);
					}
					else {
						if (result.next.length >= 4) {
							return result;
						}
						result.next.push(this.applyTimeCorrection(prayer));
					}
				}
			}
		}
		return result;
	}

	/**
	 * Call this function to correct times based on the selected city
	 * @param  {[type]} times [description]
	 * @return {[type]}       [description]
	 */
	applyTimesCorrections = (times) => {

		let city = this.getSelectedCity();
		if (!city) {
			return times;
		}

		return times.map(t => {
			let delta = city[t.id];
			var time = new Date(t.timestamp);
			time.setMinutes(time.getMinutes() + delta);
			return {
				id: 	 	t.id,
				name: 		t.name,
				nameShort: 	t.nameShort,
				time: 		this.prettyTime(time),
				timestamp: 	time.getTime()
			} 
		});
	}

	/**
	 * Call this functin to correct time based on the selected city
	 * @return {[type]} [description]
	 */
	applyTimeCorrection = (t) => {

		let city = this.getSelectedCity();
		if (!city) {
			return t;
		}

		let delta = city[t.id];
		var time = new Date(t.timestamp);
		time.setMinutes(time.getMinutes() + delta);
		return {
			id: 	 	t.id,
			name: 		t.name,
			nameShort: 	t.nameShort,
			time: 		this.prettyTime(time),
			timestamp: 	time.getTime()
		} 
	}


	/**
	 * This functin will return time string from the given timestamp
	 * @param  {[type]} time [description]
	 * @return {[type]}      [description]
	 */
	prettyTime = (time) => {
		let h = time.getHours(),
			m = time.getMinutes();
		return (h < 10 ? ('0' + h) : h)	+ ':' + (m < 10 ? ('0' + m) : m);	
	}

	/**
	 * This functin will return prayers for full month
	 * @param  {[type]} year  [description]
	 * @param  {[type]} month [description]
	 * @return {[type]}       [description]
	 */
	getFullMonthPrayers = (year, month) => {
		const key = year + '_' + month;
		return this.mPrayersHash[key];
	}

}

export let vaktija = new Vaktija();