import React from 'react'
import ReactGA from 'react-ga';

import { Container, Row, Col } from 'reactstrap'

export default class About extends React.PureComponent {

	/**
	 * Constructor
	 * @return {[type]} [description]
	 */
	constructor(props) {
		super(props);
		ReactGA.pageview(window.location.pathname + '/about-us');
	}

	/**
     * React render function
     */
    render = () => {

		return (
			<div className="app">

				<header className="app-header app-back">
					<Container>
			          <Row>
			            <Col 
			              sm={{ size: 12, offset: 0 }}
			              md={{ size: 10, offset: 1 }}
			              lg={{ size: 8, offset: 2 }}
			              xl={{ size: 6, offset: 3 }}>
							
							<div className="app-header-logo" />
							<div className="app-back-button" onClick={() => this.props.onBackClicked()}></div>
						</Col>
			          </Row>
			        </Container> 
		        </header>

				<Container>
		          <Row>
		            <Col 
		              sm={{ size: 12, offset: 0 }}
		              md={{ size: 10, offset: 1 }}
		              lg={{ size: 8, offset: 2 }}
		              xl={{ size: 6, offset: 3 }}>
		              <div className="app-content">
		                <div className="app-article">
		                	<h1>O aplikaciji</h1>
							<p><small>Vaktija.si, različica aplikacije 2.0 </small></p>
							
							<p>
								Aplikacija vaktija je avtorsko delo in last Islamske 
								skupnost v Republiki Sloveniji.
							</p>
							<p>
								Namenjena je članom islamske skupnosti in vsem muslimanom,
								ki potrebujejo informacije o času dnevnih molitev.
							</p>
							<p>
								Vse informacije in podobe, vsebovane na v spletni aplikaciji,
								so predmet avtorske zaščite in drugih oblik zaščite intelektualne 
								lastnine, v mejah veljavnih predpisov. Prepovedano je kakršno 
								koli prepisovanje, razmnoževanje ali drugačno razširjanje, 
								razen izključno za osebno uporabo ali s pisnim soglasjem avtorja.
							</p>

							<p>
								Islamska skupnost si prizadeva, da bi aplikacija delovala 
								brezhibno, vendar lahko, kljub našemu trudu, pride 
								do morebitnih napak ali odstopanj, za kar se vam v naprej 
								opravičujemo in vas prosimo, da nam takšne napake ali vaše 
								predloge za izboljšavo, sporočite na spodaj navedeni 
								elektronski naslov.
							</p>
							
							<div className="isvrs-logo" />
							<p>
								Grablovičeva 14, <br />
								p.p.2725, <br />
								1001 Ljubljana, <br />
								Slovenija <br />
								<br />
								Telefon: +386 1 2313 625 <br />
								E-mail: vaktija@islamska-skupnost.si <br />
							</p>
						</div>
		              </div>
		            </Col>
		          </Row>
		        </Container> 

			</div>
		);
	}
}