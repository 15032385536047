import React, { Component } from 'react';
import { slide as Menu } from 'react-burger-menu'
import { Container, Row, Col } from 'reactstrap'
import ReactGA from 'react-ga';
import {animateScroll as scroll} from 'react-scroll';

import './style/bootstrap.scss'
import './style/app.scss'

import { vaktija } from './services/vaktija'
import Loader from './components/loading'
import CityDialog from './components/citySelect'
import About from './components/about'
import Main from './components/main'

import initReactFastclick from 'react-fastclick';
initReactFastclick();

ReactGA.initialize('UA-131833821-1');

const Page = {
  LOADING:      1,
  CITY_SELECT:  2,
  ABOUT:        3,
  MAIN:         4
};

class App extends Component {
  
  mCitySelectManualyClicked = false

  state = {
    page: Page.LOADING,
    scrollToTop: false
  }

  constructor(props) {
    super(props);
    vaktija.registerOnInitCallback(this.onVaktijaInitialised);
    vaktija.initialise();
    ReactGA.pageview(window.location.pathname);
  }

  componentDidMount() {

    window.addEventListener('touchmove', (event) => {
         if (window.scrollY > 450 && !this.state.scrollToTop) {
             this.setState({ scrollToTop: true });
         } else if (window.scrollY < 450 && this.state.scrollToTop) {
             this.setState({ scrollToTop: false });
         }
      });

      window.addEventListener('scroll', (event) => {
         if (window.scrollY > 450 && !this.state.scrollToTop) {
             this.setState({ scrollToTop: true });
         } else if (window.scrollY < 450 && this.state.scrollToTop) {
             this.setState({ scrollToTop: false });
         }
      });
  }

  render() {

    // Render loading page
    if (this.state.page === Page.LOADING) {
      return (
        <Loader />
      );
    }

    // Render city select page
    if (this.state.page === Page.CITY_SELECT) {
      return (
        <CityDialog backButton={this.mCitySelectManualyClicked} 
            onCitySelected={this.onCitySelected.bind(this)}
            onBackClicked={() => { this.setState({ page: Page.MAIN }) }} />
      );
    }

    // Render about page
    if (this.state.page === Page.ABOUT) {
      return (
        <About onBackClicked={() => { this.setState({ page: Page.MAIN }) }} />
      );
    }

    // Render main page
    return (
      <div className="app">

        
        <header className="app-header">
          <Container>
            <Row>
              <Col 
                sm={{ size: 12, offset: 0 }}
                md={{ size: 10, offset: 1 }}
                lg={{ size: 8, offset: 2 }}
                xl={{ size: 6, offset: 3 }}>

                  <div className="app-header-content">

                    <div className="app-header-logo"/>
                    <div className={'app-scroll-to-top' + (this.state.scrollToTop && ' show')} onClick={this.scrollToTop.bind(this)}></div>

                  </div>

                  <Menu right>
                    <div className="app-menu-logo" />
                    <div>
                      <button className="menu-item" onClick={this.onCitySelectMenuItemClicked.bind(this)}>Zamenjaj mesto</button>
                      { /*<button className="menu-item" href="/">Dove</button>*/}
                      <button className="menu-item" onClick={() => { this.setState({ page: Page.ABOUT }) }}>O aplikaciji</button>
                      <a className="menu-item" target="_blank" rel="noopener noreferrer" href="http://www.islamska-skupnost.si">Islamska skupnost v RS</a>
                    </div>
                    <div className="isvrs-menu-logo" />
                  </Menu>

              </Col>
            </Row>
          </Container>  
        </header>
        

        <Container>
          <Row>
            <Col 
              style={{ position: 'relative' }}
              sm={{ size: 12, offset: 0 }}
              md={{ size: 10, offset: 1 }}
              lg={{ size: 8, offset: 2 }}
              xl={{ size: 6, offset: 3 }}>


              <div className="app-content">
                <Main />
              </div>
            </Col>
          </Row>
        </Container>  

      </div>
    );
  }

  /**
   * Scroll to top functin clicked
   * @return {[type]} [description]
   */
  scrollToTop = () => {
    setTimeout(() => {
      scroll.scrollToTop({ duration: 200, smooth: true });
    }, 10);
  }

  /**
   * This functin is called when city select menu item is clicked.
   * @return {[type]} [description]
   */
  onCitySelectMenuItemClicked = () => {
    this.mCitySelectManualyClicked = true;
    this.setState({
      page: Page.CITY_SELECT
    })
  }

  /**
   * This function is called when vaktija service was initialised.
   * @return {[type]} [description]
   */
  onVaktijaInitialised = () => {
    this.setState({
      page: !vaktija.getSelectedCity() ? Page.CITY_SELECT : Page.MAIN
    });
  }

  /**
   * Called when city is selected
   * @param  {[type]} city [description]
   * @return {[type]}      [description]
   */
  onCitySelected = (city) => {
    vaktija.setSelectedCity(city.cityid);
    this.setState({
      page: Page.MAIN
    });
  }

}

export default App;
