import React from 'react'

import Prayers from './currentPrayers'
import Calendar from './calendar'

import { vaktija } from '../services/vaktija'

export default class Main extends React.PureComponent {

	mCallbackId = 0

	/**
	 * React lifecycle method called when component was added to DOM
	 * @return {[type]} [description]
	 */
	componentDidMount = () => {

		// subscribe to data updated callbacks
		this.mCallbackId = vaktija.registerOnTimeCallback(() => {
			this.forceUpdate();
			//console.log('Main - Component updated')
		});

		// reload data when app is focues
		window.onfocus = (e) => {
			this.forceUpdate();
		};
  	}

  	/**
  	 * React callback that is triggered just before the component is removed
  	 * from DOM
  	 * @return {[type]} [description]
  	 */
  	componentWillUnmount = () => {
  		vaktija.unregisterOnTimeCallback(this.mCallbackId);
  	}



	/**
     * React render function
     */
    render = () => {
    	//console.log('Main - render called')

    	let config = vaktija.getConfig();
		return (
			<div>
				
				<div className="main-date">{config.city.name}, {config.date.day}. {config.date.monthName} {config.date.year}</div>
				<div className="main-alt-date">{config.date.hDay}. {config.date.hMonthName} {config.date.hYear}</div>

				<Prayers />
				<Calendar />
			</div>
		);
	}
}